import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
const query = graphql`
query TermsQuery {
  wpPage(isPostsPage: {eq: true}) {
    uri
    id
  }
}`;
const Terms = (props) => {
  const termsPostPage = useStaticQuery(query);
  const {
    domNode
  } = props;
  if (typeof props.terms === "undefined" || props.terms.nodes.length === 0) {
    return (<></>);
  }
  const options = JSON.parse(domNode.children[0].data);
  const className = options.textAlign ? "wp-block-post-terms has-text-align-"+options.textAlign : "wp-block-post-terms";
  const Term = (attrs) => {
    if ((!props.terms.asLink)
    || (props.terms.single && (props.terms.single.id === attrs.term.id))
    || (props.terms.asList && (typeof props.terms.single === "undefined") && (attrs.term.id === termsPostPage.wpPage.id))) {
      return (<span className={ className }>
        { attrs.term.name }
      </span>);
    }
    return (<Link
      to={ attrs.term.uri }
      className={ props.terms.asList ? undefined : className }
      title={ attrs.term.allCategories ? "Afficher tous les articles" : "Afficher les articles de la catégorie : "+attrs.term.name }
    >
      { attrs.term.name }
    </Link>);
  };
  const allCategories = {
    allCategories: true,
    id: termsPostPage.wpPage.id,
    name: "Tous les articles",
    uri: termsPostPage.wpPage.uri
  };
  if (!props.terms.asList) {
    return <Term term={ props.terms.nodes[0] }/>;
  } else {
    return (<nav className={ className }><ul className="is-style-list-no-disc">
      { [allCategories, ...props.terms.nodes].map(term => {
        return (<li key={term.id}>
          <Term term={ term } />
        </li>);
      })
      }
    </ul></nav>);
  }
};

export default Terms;
